import React from 'react'
import Slider from "../Slider";

const PlanSlider = () => {
  return (
    <div className="bg-[#F0F0F0] lg:pt-20 pb-1">
        <Slider/>
    </div>
  )
}

export default PlanSlider