import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'antd';
import mild from "../../assets/images/plans/mild.png"
import moderate from "../../assets/images/plans/moderate.png"
import severe from "../../assets/images/plans/severe.png"
import dic20 from "../../assets/images/plans/20perc.png"
import dic30 from "../../assets/images/plans/30perc.png"
import dic50 from "../../assets/images/plans/50perc.png"
import icon1 from "../../assets/images/plans/icon/restaurant_menu.png"
import icon2 from "../../assets/images/plans/icon/exercise.png"
import icon3 from "../../assets/images/plans/icon/forum.png"
import icon4 from "../../assets/images/plans/icon/video_call.png"
import icon5 from "../../assets/images/plans/icon/percent.png"
import icon6 from "../../assets/images/plans/icon/Profit.png"

const Cards = () => {
  const [index, setIndex] = useState(0);
  const cardRefs = useRef([]);
  cardRefs.current = [];

  const addToRefs = (el) => {
    if (el && !cardRefs.current.includes(el)) {
      cardRefs.current.push(el);
    }
  };

  useEffect(() => {
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [index]);

  const plans = [
    {
      title: mild,
      discount: dic20,
      duration: "90 Days",
      orgPrice: "AED 800",
      price: "AED 500",
      details: [
        "Customized Daily Diet Plan",
        "Personalized Fitness Plan",
        "Unlimited Modification",
        "3 CALLS With Health Coach",
        "FREE Root Cause Analysis",
        "Discount On ReViva Store"
      ],
      icons:[
        icon1,
        icon2,
        icon3,
        icon4,
        icon5,
        icon5,
      ]
    },
    {
      title: moderate,
      discount: dic30,
      duration: "180 Days",
      orgPrice: "AED 800",
      price: "AED 500",
      details: [
        "Customized Daily Diet Plan",
        "Personalized Fitness Plan",
        "Unlimited Modification",
        "7 CALLS With Health Coach",
        "1 Root Cause Analysis",
        "Discount On ReViva Store"
      ],
      icons:[
        icon1,
        icon2,
        icon3,
        icon4,
        icon5,
        icon5,
      ]
    },
    {
      title: severe,
      discount: dic50,
      duration: "360 Days",
      orgPrice: "AED 800",
      price: "AED 500",
      details: [
        "Customized Daily Diet Plan",
        "Personalized Fitness Plan",
        "Unlimited Modification",
        "14 CALLS With Health Coach",
        "FREE Root Cause Analysis",
        "10% Off On ReViva Store"
      ],
      icons:[
        icon1,
        icon2,
        icon3,
        icon4,
        icon6,
        icon6,
      ]
    }
  ];

  return (
    <div className='max-screen mx-auto mt-10 overflow-x-auto pb-10 flex-col'>
        <h3 className="hidden smallScreenShow font-medium text-lg">Select a plan based on the severity of your symptoms</h3>
        <div style={{ display: 'flex', gap: '16px', padding: '16px', overflowX: 'auto' }}>
        {plans.map((plan, i) => (
          <div key={i} ref={addToRefs} className="card-container">
            <Card
              bordered={false}
              style={{ width: '100%', backgroundColor: '#C4E6DF' }}
              bodyStyle={{ padding: '20px' }}
            >
              <div className="text-sm">
                <img src={plan.discount} alt="discount" className="h-7 ml-auto mb-3"/>
                <img src={plan.title} alt="plan type" className="mb-3"/>
                <p className='text-xl text-[#3B3B3B] font-Roboto font-bold italic mb-3'>Your Plan Includes <span className="text-[#01C7B1]">{plan.duration}</span> of:</p>
                {plan.details.map((detail, index) => (
                  <div key={index} className="flex items-center mb-4">
                    <img src={plan.icons[index]} alt="icon" className="h-5 mr-2" />
                    {detail}
                  </div>
                ))}
                <button className="mt-2 bg-[#27696D] w-full hover:bg-[#28696D] rounded-full font-Roboto text-white font-bold py-2 px-4">
                  Buy for <del className="text-[#95CFC9]">{plan.orgPrice}</del> {plan.price} Only
                </button>
              </div>
            </Card>
          </div>
        ))}
      </div>
      <div className="hidden smallScreenShow  w-full mt-12 relative flex justify-center">
              {plans.map(function (data) {
                return (
                  <div className="absolute bottom-0 left-auto  flex  gap-2">
                    {new Array(plans.length).fill("").map((_, i) => (
                      <span
                        key={i}
                        className={`block h-2 cursor-pointer rounded-full transition-all content-[''] ${
                          index === i ? "w-8 bg-[#3B3B3B]" : "w-4 bg-[#F0F0F0]"
                        }`}
                        onClick={() => setIndex(i)}
                      />
                    ))}
                  </div>
                );
              })}
            </div>
      <p className="hidden smallScreenShow ">If you don't know the severity of your symptoms then don't worry. Select any plan to proceed and our health coach will guide you further</p>
    </div>
  );
}

export default Cards;
