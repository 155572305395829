import React from "react";
import Hero from "./components/pricing&Plans/Hero";
import Cards from "./components/pricing&Plans/Cards";
import PlanSlider from "./components/pricing&Plans/PlanSlider";
import FaqSection from "./components/FAQs";
import Footer from "./components/getStartedComponents/Footer";

const PlansPricing = () => {
  return (
    <div className="bg-white">
      <Hero />
      <Cards />
      <PlanSlider />
      <div className="mb-10 lg:mb-20">
        <FaqSection />
      </div>
      <Footer />
    </div>
  );
};

export default PlansPricing;
